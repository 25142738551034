import useStore from '../store/store';
import { sendRequest } from '../api/websocketApi';
import moment from 'moment';

const SetGatewayPropertyValue = (uuid, name, value) => {
  const setFormError = useStore.getState().setFormError;
  const setLoading = useStore.getState().setLoading;
  const setNetworkStateProperties =
    useStore.getState().setNetworkStateProperties;

  setLoading(true);
  sendRequest(
    {
      endpoint: '/gateway/set-property-value',
      body: { uuid, name, value },
    },
    (data) => {
      const { success, errCode } = data;
      if (success) {
        const newProperties = [
          {
            id: 'g.' + uuid + '.' + name,
            v: value,
            t: moment().toISOString(),
          },
        ];
        setNetworkStateProperties(newProperties);
        setLoading(false);
      } else if (!success && errCode) {
        setLoading(false);
        setFormError(errCode);
      }
    }
  );
};

export default SetGatewayPropertyValue;
